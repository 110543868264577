.containerPaperCard {
    height: 70px;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 0 5px;
}

.containerPaperCard h1 {
    font-size: 20px;
    color: var(--white);
}

.containerPaperCard span {
    font-size: 14px;
    color: var(--white);
}

.containerPaperCard svg {
    opacity: 0.6;
}

.container-card-total {
    background-color: var(--primary);
    color: var(--white);
    width: 100%;
    display: flex;
    justify-content: flex-end;
    padding: 6px;
    gap: 12px;
}

.container-card-total h1 {
    font-size: 38px;
}
.container-card-total h2 {
    font-size: 16px;
}